.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-slider {
  width: 100%;
}


div#slideback {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  align-content: center;
  height: 100%;
}
.content-slider-wrapper {
  display: block;
  text-align: center;
  align-items: center;
  position: relative;
}


.slider-content-inner {
  margin-left: auto;
  margin-right: auto;
  width: auto;
  display: inline-block;
}

.slider-background-text {
  padding: 0.3rem;
  width: 100%;
}
.slide--button-container {
  width: 100%;
  word-wrap: normal;
  white-space: normal;

}
.slide--button-container .btn {
  width: 100%;
  display: block;
  margin: auto;
  color: white;
  font-size: 16px;
  font-weight: 600;

}
.slider-content {
  font-family: var(--font-normal);
  color: var(--font-color);
  margin-bottom: 0;
  font-size: 16px;
  letter-spacing: 1px;
  height: 100%;
  position: relative;
  width: 100%;
  background: rgba(0,0,0,0.1);
  margin: 0;
}
.slider-content h1,
.slider-content h2 {
  color: var(--color-primary);
  font-size: 1.6rem;
}



.slider-content {
  display: block;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
.slider-icon,
.slider-icon img {
  width: 100%;
}

@media screen and (min-width: 35.5rem) {


  .slider-icon,
  .slider-icon img {
    width: 100%;
    position: relative;
    height: auto;
    min-height: inherit;
    left: inherit;
  }

  .slider-icon img {
    max-width: 100%;
    max-height: none;
    height: auto;
  }
  .slider-icon,
  .slider-icon img {
    /*width: 100%;*/
    position: relative;
    height: auto;
    min-height: inherit;
    left: inherit;
  }
  .slider-icon {
   /* height: 900px;*/
    width: 100%;
  }
  .slider-icon img {
    max-width: none;
   /* max-height: 900px;*/
    height: auto;
    width: 100%;
  }

}
@media screen and (min-width: 64rem) {
  .slider-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
  }
  .slider-content h1,
  .slider-content h2 {
    color: var(--color-primary);
    font-size: 2rem;
  }
  .slider-content {
    z-index: 5;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    display: block;
    height: 100%;
    width: 100%;
    bottom: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    font-size: 20px;
  }
  .slider-background-text {
    width: 70%;
  }
  .slide--button-container {
    width: 70%;
  }
  .slide--button-container .btn {
    width: 70%;
  }
}

@media screen and (min-width: 80rem) {
  .slider-icon img {
    max-height: none;
  }
  .slider-background-text {
    width: 50%;
  }
  .slide--button-container {
    width: 50%;
  }
  .slide--button-container .btn {
    width: 55%;
  }
}
.slider-wrapper {
    height: 100%;
    position: relative;
}
