.news {
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 2rem;
}

section.news.news--content {
  background: none;
}
.news .container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 1rem;
}

.news-wrapper {
  width: 100%;
  padding-bottom: 1rem;
}

.news--boxen {
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
}
section.news.news--content .news--boxen {
  justify-content: flex-start;
}

.news--box {
  width: 100%;
  margin-bottom: 2rem;
}

.news--box {
  text-align: left;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  height: auto;
}
.news--box--img-container {
  height: 16rem;
  overflow: hidden;
}
.news--box--content h3 {
  font-size: 1.3rem;
  font-weight: 200;
  padding-bottom: 0.8rem;
  padding-top: 1rem;
  border-bottom: solid 1px #ddddde;
}
.news--box--content h3::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 20%;
  content: '';
  border-bottom: solid 3px #969593;
}
.news--box--content {
  margin-bottom: 3rem;
}

.news--box-date {
  margin-top: 1rem;
}

.news--box--link-container {
  position: relative;
  text-align: right;
}

@media screen and (min-width: 35.5rem) {

}

@media screen and (min-width: 48rem) {
  section.news.news--content .news--box {
    width: calc(98% / 2);
    margin-right: 1%;
  }

}

@media screen and (min-width: 64rem) {
  section.news.news--content .news--box {
    width: calc(97% / 3);
    margin-right: 1%;
  }
  .news--box--link-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}