.kacheln {
  margin-top: var(--gutter);
}

.boxen {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start;
  align-items: stretch;
  padding-bottom: 2rem;
}

.box {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
}
.box-inner {
  overflow: hidden;
  background: var(--color-primary);
  display: block;
  position: relative;
  padding-bottom: 10rem;
}

.box-leistung--content {
  margin-top: 0;
}

.box-leistung--inner h2 {
  font-family: var(--font-normal);
  color: white;
  text-align: center;
}

.box-leistung--round {
  width: 90px;
  height: 90px;
  border-radius: 100px;
  border: 2px solid white;
  padding: 15px;
  margin: 0.5rem auto;
  background: transparent;
}

.box-leistung--round img {
  height: 60px;
  width: 60px;
}
.box-leistung--content {
  position: absolute;
  padding: 1rem;
  margin-top: 0;
  background: var(--color-primary);
  height: 100%;
  bottom: calc(13rem - 100%);
  transition: bottom 0.5s ease-in;
}
.box-inner:hover  .box-leistung--content {
  bottom: 0;
}
.box-leistung--inner {
  color: white;
}
.box-leistung--inner p {
  margin-top: 1rem;
}
.box-leistung--inner .btn {
  margin-top: 1rem;
  display: inline-block;
}



@media screen and (min-width: 35.5rem) {
  .box-leistung--content {
    /*top: 60%;*/
  }
  .box {
    width: calc(98% / 2);

  }
}

@media screen and (min-width: 64rem) {
  .box {
    width: calc(97% / 3);
    margin-bottom: 0;
  }
}

