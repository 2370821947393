.logo-footer {
  background: #f6f5f3;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.logo-footer img{
  width: 15rem;
}
.sub-footer {
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;
}

.sub-footer ul {
  list-style: none;
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  justify-content: center;
}

.sub-footer ul li {
  padding-right: 1rem;
}
