.meinungen-wrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.meinungen-inner {
  margin-top: 1rem;
  display: block;
  position: relative;
}

.meinungen-wrapper h2 {
  margin-top: 0;
}

div#mmeinungen {
  background: transparent;
  margin-left: 0;
}

.swiper-wrapper {
  background: transparent;
}

.swiper-slide.meinungs-slide {
  background: transparent;
}

.meinungen-img-wrapper {
  position: absolute;
  top: 0;
}

.meinungen-flex {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.meinungen-right {
  display: none;

  position: relative;
  width: 100%;
  min-height: 15rem;
}

.meinungen-left {
  width: 100%;
}

.meinungen-img-wrapper {
  top: -20%;
  right: 0;
  text-align: right;
}

.meinungen-img-wrapper img {
  width: 90%;
  max-width: 100%;
  height: auto;
}

.meinungsbox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: stretch;

  padding: .5rem 1rem;
  padding-bottom: 3rem;
  width: 100%;
  height: 100%;
}

.meinungen-img {
  width: 100%;
}

.meinungen-text {
  width: 100%;
  text-align: left;
  font-family: var(--font-header);
  font-size: 1.8rem;
  line-height: 1.8rem;
  font-weight: 200;
  height: 100%;
  position: relative;
  display: block;
}
.meinungen-bild {
  display: flex;
  width: 10rem;
  height: 10rem;
  justify-content: center;
  align-self: center;
  overflow: hidden;
  margin-bottom: var(--gutter);
  margin-left: auto;
  margin-right: auto;
}

.meinungen-bild img {
  height: 100%;
  width: auto;
}

.meinungen-text p {
  margin-bottom: calc(var(--gutter) * 0.5);
}

.meinungen-name {
  font-size: 0.8rem;
  line-height: 1.2;
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: var(--font-light);
  margin-top: 2rem;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--color-primary);
}
@media screen and (min-width: 48rem) {

  .meinungen-right {
    width: 60%;
    display: block;
  }

  .meinungen-left {
    width: 40%;
  }
  .meinungsbox {
    width: 85%;
  }

  .meinungen-bild {
    margin-bottom: 0;
  }

  .meinungen-img {
    width: 20%;
  }

  .meinungen-text {
    width: 80%;
    padding-left: 2rem;
  }

}

@media screen and (min-width: 80rem) {

}