/*
 * Filename: theme.css
 *
 * Stylesheet for all theme relevant attributes like colors, fonts ...
 */

@font-face {
  font-family: 'Conv_HandTest';
  src: url('fonts/HandTest.eot');
  src: local('☺'), url('fonts/HandTest.woff') format('woff'), url('fonts/HandTest.ttf') format('truetype'), url('fonts/HandTest.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
:root {

  /** Schriftgrößen */
  --font-size: 1rem;
  --font-size-title: 2.5rem;
  --font-size-heading: 2rem;
  --font-size-subheading: 1.25rem;
  --font-size-small: .8125rem;

  /** Raster */
  --line-height: 1.5em;

  --gutter: 1rem;
  --gutter-horizontal: 1rem;

  /** Farben */
  --font-color: #444444;
  --code: #c7254e;
  --error: #c7254e;
  --success: #38ae67;
  --color-grey-light: #ccc;
  --color-grey-dark: #333;
  --color-primary: #ffd100;
  --color-secondary: #c6a57f;
  --color-primary-hover: color(#ffd100 a(80%));
  /** Fonts **/

  --font-header: 'Conv_HandTest';
  --font-normal: 'Montserrat', sans-serif;
  --font-light: 'Montserrat', sans-serif;
  --font-bold: 'Montserrat', sans-serif;
}


@custom-media --viewport-sm (min-width: 35.5rem);
@custom-media --viewport-md (min-width: 48rem);
@custom-media --viewport-lg (min-width: 64rem);
@custom-media --viewport-xl (min-width: 80rem);
