/*
 * Filename: forms.css
 *
 * Default Styles for Forms.
 */

label,
.pure-form label {
  display: block;
}

textarea,
.pure-form textarea {
  display: block;
}

.pure-form fieldset {
  margin: 0;
  border: 0;
  padding: 0;
}

.btn {
  border-style: none;
  border-radius: 0;
  padding: 0.7rem 1rem;
  cursor: pointer;
  transition: background .7s, color .7s;
  outline: none;
  display: inline-block;
}

.btn-primary {
  background: var(--color-primary);
  color: #444444;
  font-size: 16px;
  font-weight: 600;
}

.btn-primary:hover {
  background: var(--color-secondary);
}

.btn-secondary {
  background: white;
  color: black;
}

.btn-secondary:hover {
  background: var(--color-secondary);
  color: white;
}


