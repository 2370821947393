.rechen ul {
  display: block;
}

.rechen li:before {
  content: '';
  margin: 0;
  padding: 0;
  height: 0;
  width: 0;
}

.rechen li {
  width: 100%;
  padding: 0.2rem;
  text-align: right;
  margin-bottom: 0;
  min-height: 2rem;
}

.rechen-head-soll {
  text-align: center!important;
  font-weight: 800;
}

.rechen-head-haben {
  text-align: center !important;
  font-weight: 800;
}

.pure-g.rechen {
  width: 65%;
}

@media screen and (min-width: 64rem) {

  .rechen-head-soll {
    padding-left: 180px !important;
  }

  .rechen-head-haben {
    padding-left: 60px !important;
  }

  .pure-g.rechen {
    width: 65%;
  }

  .rechen span {
    display: inline-block;
    width: 45%;
  }

  span.rechen-right {
    width: 65%;
  }

  span.rechen-left {
    width: 25%;
  }

  .rechen-div {
    width: 38%;
  }
}