header {
  position: relative;
  color: white;;
  width: 100%;
  border-bottom: solid 1px var(--color-secondary);
}
.main-nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.navigation {
  text-align: left;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.header-logo {
  display: block;
}
.header-logo a {
  position: relative;
}

.header-logo img {
  width: 10rem;
  height: auto;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

