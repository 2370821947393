.parallax {

  background: var(--color-primary) no-repeat fixed center top;
  background-size: cover;
  display: block;
  position: relative;
}

.parallax-over::before {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.parallax--content {
  z-index: 5;
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: block;
  position: relative;
  color: white;
  text-align: center;
  font-size: 1.2rem;
}

.parallax--content h2 {
  font-family: var(--font-bold);
  margin-bottom: 1rem;
  display: inline-block;
  position: relative;
  margin-top: 0;
  color: var(--font-color);
}
.parallax-over .parallax--content h2 {
  color: white;
}
.parallax-over .parallax--content h2::after {
  content: '';
  width: 80%;
  height: 4px;
  display: inline-block;
  position: relative;
  margin: 1rem auto 0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffd100+1,ffffff+78 */
  background: var(--color-primary); /* Old browsers */
  background: -moz-radial-gradient(center, ellipse cover,  var(--color-primary) 1%, rgba(255, 255, 255, 0) 78%); /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover,  var(--color-primary) 1%, rgba(255, 255, 255, 0) 78%); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center,  var(--color-primary) 1%, rgba(255, 255, 255, 0) 78%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd100', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
 .parallax--content h2::after {
  content: '';
  width: 80%;
  height: 2px;
  display: inline-block;
  position: relative;
  margin: 1rem auto 0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffd100+1,ffffff+78 */
  background: var(--font-color); /* Old browsers */
  background: -moz-radial-gradient(center, ellipse cover, var(--font-color) 1%, rgba(255, 255, 255, 0) 78%); /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, var(--font-color) 1%, rgba(255, 255, 255, 0) 78%); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, var(--font-color) 1%, rgba(255, 255, 255, 0) 78%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd100', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.parallax--content h3 {
  margin-top: 1rem;
  font-weight: 200;
  font-size: 1.6rem;
  margin-bottom: 1rem;
  display: block;
  position: relative;
}

/*.parallax--content-trenner {
  content: '';
  width: 15rem;
  height: 4px;
  display: inline-block;
  position: relative;
  margin: 1rem auto 2rem;
  !* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffd100+1,ffffff+78 *!
  background: #ffd100; !* Old browsers *!
  background: -moz-radial-gradient(center, ellipse cover, #ffd100 1%, rgba(255, 255, 255, 0) 78%); !* FF3.6-15 *!
  background: -webkit-radial-gradient(center, ellipse cover, #ffd100 1%, rgba(255, 255, 255, 0) 78%); !* Chrome10-25,Safari5.1-6 *!
  background: radial-gradient(ellipse at center, #ffd100 1%, rgba(255, 255, 255, 0) 78%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd100', endColorstr='#ffffff',GradientType=1 ); !* IE6-9 fallback on horizontal gradient *!
}*/

.parallax--content ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;

}

.parallax--content li {
  width: 100%;
  margin-bottom: 2rem;
  text-align: center;
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.parallax--content li::before {
  content: url("img/check.svg");
  display: block;
  position: relative;
  top: 0;
  margin: auto;
  padding-bottom: 1rem;
  width: 4rem;
  height: 4rem;
}

@media screen and (min-width: 64rem) {
  .parallax--content li {
    width: 50%;
    padding-left: 5rem;
    padding-top:0;
    text-align: left;
    display: block;
  }
  .parallax--content li::before {
    top: auto;
    left: 0;
    position: absolute;
  }
}